import { required, numeric } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import store from '../../../../../store';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addAppCriteria',
  components: { ModelSelect },
  data() {
    return {
      parent_value_set_id: null,
      catTypeForSpec: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      appCritListIndex: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      criteriaForm: {
        applicationCriteriaHdrId: null,
        criteriaName: null,
        version: null,
        criteria_details: [
          {
            operator: null,
            application_detail_id: null,
            category_code: null,
            category_id: null,
            category_meaning: null,
            sub_category_code: null,
            sub_category_meaning: null,
            sub_sub_category_code: null,
            sub_sub_category_meaning: null,
            range_from: null,
            range_to: null,
            criteria_specification: null,
            criteria_specification_meaning: null,
            uom_code: null,
            uom_meaning: null,
            operator_meaning: null
          }
        ]
      },
      criteriaDetailFeilds: [
        {
          key: 'category_meaning',
          label: 'Category'
        },
        {
          key: 'sub_category_meaning',
          label: 'Sub Category'
        },
        {
          key: 'sub_sub_category_meaning',
          label: 'Sub Sub Cat',
          class: 'd-none'
        },
        {
          key: 'criteria_specification_meaning',
          label: 'Specification'
        },
        {
          key: 'range_from',
          label: 'From'
        },
        {
          key: 'range_to',
          label: 'To'
        },
        {
          key: 'uom_meaning',
          label: 'UOM'
        },
        {
          label: 'Operator',
          key: 'operator_meaning'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  validations: {
    criteriaForm: {
      criteriaName: {
        required
      },
      version: { required, numeric }
    }
  },
  mounted() {
    this.getLegalEntity();
    this.eventBus.$on('criteriaDetail', criteriaDetail => {
      this.getCriteriaDetail(criteriaDetail.application_criteria_hdr_id);
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditAppCriteria();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.criteriaForm.applicationCriteriaHdrId
          });
        }
      }
    });
  },
  methods: {
    addEditAppCriteria() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const criteriaDetail = this.criteriaForm.criteria_details.map(
          detatil => {
            return {
              application_detail_id: detatil.application_detail_id,
              category_code: detatil.category_code,
              criteria_specification: detatil.criteria_specification,
              operator: detatil.operator,
              range_from: detatil.range_from,
              range_to: detatil.range_to,
              sub_category_code: detatil.sub_category_code,
              sub_sub_category_code: detatil.sub_sub_category_code,
              uom_code: detatil.uom_code
            };
          }
        );
        const payload = {
          application_criteria_details: criteriaDetail,
          criteriaHdrId: this.criteriaForm.applicationCriteriaHdrId,
          criteriaName: this.criteriaForm.criteriaName,
          version: this.criteriaForm.version
        };
        this.loader = true;
        this.$store
          .dispatch('workflow/addEditAppCriteriaList', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getCriteriaDetail(criteriaId) {
      this.loader = true;
      this.$store
        .dispatch('workflow/getAppCriteriaDetails', criteriaId)
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (response.status === 200) {
            this.criteriaForm = response.data.data;
            if (this.criteriaForm.application_details.length > 0) {
              this.criteriaForm.criteria_details = this.criteriaForm.application_details;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store
        .dispatch('receipt/getOrganizationLov', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.legalEntityList = leagalEntity;
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'WF_CRITERIA_CAT') {
        this.criteriaForm.criteria_details[
          this.appCritListIndex
        ].category_meaning = item.value_meaning;
        this.criteriaForm.criteria_details[
          this.appCritListIndex
        ].category_code = item.value_code;
        this.criteriaForm.criteria_details[this.appCritListIndex].category_id =
          item.value_set_dtl_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT
      ) {
        this.criteriaForm.criteria_details[
          this.appCritListIndex
        ].sub_category_meaning = item.value_meaning;
        this.criteriaForm.criteria_details[
          this.appCritListIndex
        ].sub_category_code = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.WF_CRITERIA_SPEC) {
        this.criteriaForm.criteria_details[
          this.appCritListIndex
        ].criteria_specification_meaning = item.value_meaning;
        this.criteriaForm.criteria_details[
          this.appCritListIndex
        ].criteria_specification = item.value_code;
      } else if (this.vsetCode === 'OPERATOR_OPTION') {
        this.criteriaForm.criteria_details[
          this.appCritListIndex
        ].operator_meaning = item.value_meaning;
        this.criteriaForm.criteria_details[this.appCritListIndex].operator =
          item.value_code;
      } else if (this.vsetCode === 'UOM') {
        this.criteriaForm.criteria_details[this.appCritListIndex].uom_meaning =
          item.value_meaning;
        this.criteriaForm.criteria_details[this.appCritListIndex].uom_code =
          item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      if (vsetCode === appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT) {
        this.parent_value_set_id = this.criteriaForm.criteria_details[
          index
        ].category_id;
      } else {
        this.parent_value_set_id = null;
      }
      if (vsetCode === appStrings.VALUESETTYPE.WF_CRITERIA_SPEC) {
        this.catTypeForSpec = this.criteriaForm.criteria_details[
          index
        ].sub_category_code;
      } else {
        this.catTypeForSpec = null;
      }
      this.vsetCode = vsetCode;
      this.appCritListIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.criteriaForm.criteria_details.push({
          operator: null,
          application_detail_id: null,
          category_code: null,
          category_meaning: null,
          category_id: null,
          sub_category_code: null,
          sub_category_meaning: null,
          sub_sub_category_code: null,
          sub_sub_category_meaning: null,
          range_from: null,
          range_to: null,
          criteria_specification: null,
          criteria_specification_meaning: null,
          uom_code: null,
          uom_meaning: null,
          operator_meaning: null
        });
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    removeRow(index) {
      this.criteriaForm.criteria_details.splice(index, 1);
    },
    rowSelected() {},
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'WF_CRITERIA_CAT') {
        this.criteriaForm.criteria_details[index].category_meaning = null;
        this.criteriaForm.criteria_details[index].category_code = null;
        this.criteriaForm.criteria_details[index].category_id = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT) {
        this.criteriaForm.criteria_details[index].sub_category_meaning = null;
        this.criteriaForm.criteria_details[index].sub_category_code = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.WF_CRITERIA_SPEC) {
        this.criteriaForm.criteria_details[
          index
        ].criteria_specification_meaning = null;
        this.criteriaForm.criteria_details[index].criteria_specification = null;
      } else if (vsetCode === 'OPERATOR_OPTION') {
        this.criteriaForm.criteria_details[index].operator_meaning = null;
        this.criteriaForm.criteria_details[index].operator = null;
      } else if (vsetCode === 'UOM') {
        this.criteriaForm.criteria_details[index].uom_meaning = null;
        this.criteriaForm.criteria_details[index].uom_code = null;
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('criteriaDetail');
    this.unsubscribe();
  }
};
