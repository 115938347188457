import commonHelper from '@/app/utility/common.helper.utility';
import addAppCriteria from './addAppCriteria';
import { ModelSelect } from 'vue-search-select';
export default {
  name: 'applicationCriteria',
  components: { addAppCriteria, ModelSelect },
  props: { showAppCriteriaModal: Boolean },
  watch: {
    currentPage: function() {
      this.getCriteriaList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCriteriaList();
    }
  },
  data() {
    return {
      appCriteria: null,
      appVersion: null,
      active: null,
      timeOut: null,
      showAddGroup: false,
      payload: {},
      unsubscribe: null,
      ruleName: null,
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      criteriaList: [],
      criteriaFeilds: [
        {
          key: 'criteria_name',
          label: 'Application Criteria'
        },
        {
          key: 'version'
        }
      ]
    };
  },
  mounted() {
    this.getCriteriaList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddGroup = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'workflow/getAppCriteriaList',
            'applicationCriteria',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getCriteriaList() {
      this.loader = true;
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        criteria_name: this.appCriteria,
        version: this.appVersion
      };
      this.$store
        .dispatch('workflow/getAppCriteriaList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.criteriaList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.appCriteria = null;
      this.appVersion = null;
      this.getCriteriaList();
    },
    hideGroupModal() {
      this.showAddGroup = false;
    },
    rowSelected(item) {
      this.showAddGroup = true;
      if (this.showAppCriteriaModal) {
        this.$emit('selectedAppCriteria', item);
      } else {
        this.timeOut = setTimeout(() => {
          this.eventBus.$emit('criteriaDetail', item);
        }, 0);
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
    this.unsubscribe();
  }
};
