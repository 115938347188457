import { required, numeric } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import MasterGroup from '../../workFlowGroup';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addMasterRule',
  components: { ModelSelect, MasterGroup },
  data() {
    return {
      setTimeVsetCode: null,
      tablePrameter: null,
      showFlexField: false,
      showMasterGroupModal: false,
      parent_value_set_id: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      groupListIndex: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      ruleForm: {
        master_rule_hdr_id: 0,
        rule_name: null,
        active: false,
        version: null,
        le_id: null,
        le_name: null,
        rule_details: [
          {
            info_only:false,
            sequence: null,
            master_rule_dtl_id: null,
            rule_type_vset: null,
            r_code: null,
            selected_val_id: null,
            selected_val_name: null
          }
        ]
      },
      ruleDetail: [
        {
          sequence: null,
          master_rule_dtl_id: null,
          rule_type_vset: null,
          r_code: null,
          info_only: false,
          selected_val_id: null,
          selected_val_name: null
        }
      ],
      ruleDetailFeilds: [
        {
          key: 'rule_type_vset',
          label: 'Rule Type'
        },
        {
          key: 'r_code',
          label: 'Entity Name'
        },
        {
          key: 'selected_val_name',
          label: 'Column Name'
        },
        {
          key: 'sequence'
        },
        {
          key: 'info_only',
          label: 'Info only'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ],
      flexFieldList: [],
      fledField: [
        {
          key: 'segment_name'
        },
        {
          key: 'segment_sequence'
        }
      ]
    };
  },
  validations: {
    ruleForm: {
      rule_name: {
        required
      },
      version: { required, numeric }
    },
    selectedLegalEntity: {
      value: {
        required
      }
    }
  },
  mounted() {
    this.eventBus.$on('groupDetail', groupDetail => {
      this.getRuleDetail(groupDetail.master_rule_hdr_id);
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditRule();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.ruleForm.master_rule_hdr_id
          });
        }
      }
    });
  },
  methods: {
    addEditRule() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const ruleDetail = this.ruleForm.rule_details.map(detatil => {
          return {
            column_name: detatil.selected_val_name,
            master_rule_dtl_id: detatil.master_rule_dtl_id,
            rule_type_vset: detatil.rule_type_vset,
            selected_val_id: detatil.selected_val_id,
            info_only: detatil.info_only,
            sequence: detatil.sequence
          };
        });
        const payload = {
          active: this.ruleForm.active,
          le_id: this.selectedLegalEntity.value,
          master_rule_hdr_id: this.ruleForm.master_rule_hdr_id,
          rule_details: ruleDetail,
          rule_name: this.ruleForm.rule_name,
          version: this.ruleForm.version
        };
        this.loader = true;
        this.$store
          .dispatch('workflow/addEditMasterRule', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.ruleForm.le_name = this.selectedLegalEntity.text;
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
              this.ruleForm = response.data.data;
              this.ruleForm.rule_details =
                this.ruleForm.rule_details.length > 0
                  ? this.ruleForm.rule_details
                  : this.ruleDetail;
              this.selectedLegalEntity.value = this.ruleForm.le_id;
              this.selectedLegalEntity.text = this.ruleForm.le_name;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getRuleDetail(groupHdrId) {
      this.loader = true;
      this.$store
        .dispatch('workflow/getMasterRuleDetail', groupHdrId)
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (response.status === 200) {
            this.ruleForm = response.data.data;
            this.ruleForm.rule_details =
              this.ruleForm.rule_details.length > 0
                ? this.ruleForm.rule_details
                : this.ruleDetail;
            this.selectedLegalEntity.value = this.ruleForm.le_id;
            this.selectedLegalEntity.text = this.ruleForm.le_name;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openModalToSelectEntityName(index) {
      if (this.ruleForm.rule_details[index].rule_type_vset === null) {
        return alert(appStrings.DEPENDENTVSETMSG.RULETYPEMSG);
      }
      this.groupListIndex = index;
      this.ruleForm.rule_details[this.groupListIndex].selected_val_name = null;
      this.ruleForm.rule_details[this.groupListIndex].selected_val_id = null;
      if (
        this.ruleForm.rule_details[this.groupListIndex].rule_type_vset ===
          'RT_GROUP' ||
        this.ruleForm.rule_details[this.groupListIndex].rule_type_vset ===
          'RT_COMMENT_GROUP'
      ) {
        this.showMasterGroupModal = true;
      } else {
        const ruleType = this.ruleForm.rule_details[this.groupListIndex]
          .rule_type_vset;
        let vsetCode =
          ruleType === 'RT_POS'
            ? appStrings.VALUESETTYPE.POSITION
            : ruleType === 'RT_PERSON'
            ? 'SYSTEM_TABLES'
            : ruleType === 'RT_NEXT_LVL'
            ? 'TERMINATION_TYPE'
            : ruleType === 'RT_HEAD'
            ? 'EMPLOYEE_ORG_FORM'
            : '';
        if (vsetCode !== 'EMPLOYEE_ORG_FORM') {
          this.openValueSetModal(vsetCode, index);
        } else {
          if (this.selectedLegalEntity.text) {
            this.openFlexfieldModal();
          } else {
            alert(appStrings.DEPENDENTVSETMSG.LEMSG);
          }
        }
      }
    },
    selectColumn(index) {
      if (this.ruleForm.rule_details[index].rule_type_vset === null) {
        return alert(appStrings.DEPENDENTVSETMSG.RULETYPEMSG);
      }
      if (
        this.ruleForm.rule_details[this.groupListIndex].rule_type_vset ===
        'RT_PERSON'
      ) {
        this.tablePrameter = this.ruleForm.rule_details[
          this.groupListIndex
        ].selected_val_id;
        this.openValueSetModal(
          appStrings.VALUESETTYPE.TABLE_COLUMNS,
          this.groupListIndex
        );
      } else if (
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id ===
        'WF_RT_HEAD'
      ) {
        if (!this.selectedLegalEntity.text) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        this.openFlexfieldModal();
      } else if (
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id ===
        'WF_RT_POS'
      ) {
        this.openValueSetModal(
          appStrings.VALUESETTYPE.POSITION,
          this.groupListIndex
        );
      } else {
        alert(appStrings.DEPENDENTVSETMSG.ENTITYMSG);
      }
    },
    selectedGroup(group) {
      this.hideGroupModal();
      this.ruleForm.rule_details[this.groupListIndex].r_code = group.group_name;
      this.ruleForm.rule_details[this.groupListIndex].selected_val_id =
        group.group_id;
      this.ruleForm.rule_details[this.groupListIndex].selected_val_name =
        group.group_name;
    },
    hideGroupModal() {
      this.showMasterGroupModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = item.org_id;
        this.selectedLegalEntity.text = item.org_name;
      } else if (this.vsetCode === 'WF_RULE') {
        this.ruleForm.rule_details[this.groupListIndex].rule_type_vset =
          item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TABLE_COLUMNS) {
        this.ruleForm.rule_details[this.groupListIndex].selected_val_name =
          item.value_code;
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id =
          item.table_name;
      } else if (this.vsetCode === 'SYSTEM_TABLES') {
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id =
          item.table_name;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.POSITION &&
        this.ruleForm.rule_details[this.groupListIndex].rule_type_vset !==
          'RT_NEXT_LVL'
      ) {
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id =
          item.value_code;
        this.ruleForm.rule_details[this.groupListIndex].r_code =
          item.value_code;
        this.ruleForm.rule_details[
          this.groupListIndex
        ].selected_val_name = item.value_meaning;
      } else if (
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id ===
        'WF_RT_POS'
      ) {
        this.ruleForm.rule_details[this.groupListIndex].selected_val_name =
          item.value_code;
      } else if (this.vsetCode === 'TERMINATION_TYPE') {
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id =
          item.value_code;
      } else {
        this.ruleForm.rule_details[this.groupListIndex].r_code =
          item.value_code;
        this.ruleForm.rule_details[this.groupListIndex].selected_val_name =
          item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      if (index === null) {
        this.parent_value_set_id = null;
      }
      if (this.ruleForm.rule_details[index].selected_val_id !== 'WF_RT_POS') {
        this.ruleForm.rule_details[index].selected_val_name = null;
        this.ruleForm.rule_details[index].selected_val_id = null;
      }
      this.vsetCode = vsetCode;
      this.groupListIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
      this.tablePrameter = null;
    },
    addNewRow() {
      if (this.editMode) {
        this.ruleForm.rule_details.push({
          sequence: null,
          master_rule_dtl_id: null,
          rule_type_vset: null,
          info_only: false,
          r_code: null,
          selected_val_id: null,
          selected_val_name: null
        });
      }
    },
    removeRow(index) {
      this.ruleForm.rule_details.splice(index, 1);
    },
    openFlexfieldModal() {
      this.showFlexField = true;
      const payload = {
        module_id: 2,
        le_id: this.selectedLegalEntity.value
      };
      this.loader = true;
      this.$store
        .dispatch('hrms/getHrOrgFlexField', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.flexFieldList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideFlexField() {
      this.showFlexField = false;
    },
    selectedFlexField(item) {
      if (
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id ===
        'WF_RT_HEAD'
      ) {
        this.ruleForm.rule_details[this.groupListIndex].selected_val_name =
          item.value_set_name;
      } else {
        this.ruleForm.rule_details[this.groupListIndex].selected_val_id =
          item.flexfield_segment_id;
        this.ruleForm.rule_details[this.groupListIndex].r_code =
          item.value_set_name;
        this.ruleForm.rule_details[this.groupListIndex].selected_val_name =
          item.value_set_name;
      }
      this.showFlexField = false;
    },
    openValueSetModalForLe(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    rowSelected() {},
    clearVsetValues(segmentCo, index) {
      if (segmentCo === 'LEGAL_ENTITY_LIST') {
        this.selectedLegalEntity.value = null;
        this.selectedLegalEntity.text = null;
      } else if (segmentCo === 'WF_RULE') {
        this.ruleForm.rule_details[index].rule_type_vset = null;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.eventBus.$off('groupDetail');
    this.unsubscribe();
  }
};
